import Container from "~/components/layout/Container";
import type { MekanikTesisatListesi } from "../mekanik-tesisatlar+/_index";


export default function MekanikTesisatlarComponent({ services }: { services: typeof MekanikTesisatListesi }) {
  return (
    <div className="py-20">
      <Container className="flex-wrap">
        <h2 className="text-3xl w-full justify-center font-black font-source-sans-pro text-center text-[32px] mb-5">Mekanik Tesisatlar</h2>
        <p className="flex w-full justify-center text-center font-extralight font-source-sans-pro not-italic mb-10 text-lg md:text-[24px] font-raleway text-[#444444]">
          Al-De Mühendislik, Modern Mekanik Çözümlerle Konforlu ve Güvenli Yaşam Alanları Sağlamak İçin Hizmetler Sunar.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          {services.map((service) => (
            <a key={service.title} href={`/mekanik-tesisatlar/${service.id}`} className="bg-gray-200 relative group mb-8 overflow-hidden">
              <img src={service.image} alt={service.title} className="group-hover:shadow-lg w-full md:h-[400px] mix-blend-multiply object-cover" />
              <div className="p-2 absolute bottom-0 left-0 w-full z-10 bg-black/40">
                <h3 className="md:text-base text-center font-bold font-raleway text-[16px] text-white">{service.title}</h3>
                <p className="mt-2 text-base md:text-[14px] font-ibm-plex text-white h-0 overflow-hidden group-hover:overflow-visible group-hover:h-auto">{service.description}</p>
              </div>
            </a>
          ))}
        </div>
      </Container>
    </div>
  );
};