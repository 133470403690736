import { type MetaFunction, useLoaderData } from "@remix-run/react";
import Container from "~/components/layout/Container";
import { references } from "../_index";
import References from "../features/References";
import ServicesComponent from "../features/Services";
import Slogans from "../features/Slogans";
export const HizmetListesi = [
  {
    id: 1,
    title: "YERDEN ISITMA",
    description: "Yerden ısıtma sistemleri, evler ve iş yerleri için gerekli olan enerjiyi verimli bir şekilde sağlar.",
    image: "/assets/yerden-isitma.jpg", // Görsel dosya yolunu güncelle
  },
  {
    id: 2,
    title: "ISI POMPASI",
    description: "Isı pompası ile ısıtma sistemleri, enerji verimliliğini artırarak konut ve sanayi alanlarında kullanılabilir.",
    image: "/assets/isi-pompasi-ile-sogutma-isitma.webp", // Görsel dosya yolunu güncelle
  },
  {
    id: 3,
    title: "ALÜMİNYUM ve ÇELİK RADYATÖRLER",
    description: "Alüminyum ve çelik radyatörler, ısıtma sistemleri için en uygun çözümlerdir.",
    image: "/assets/aluminyum-ve-celik-radyatorler.webp", // Görsel dosya yolunu güncelle
  },
  {
    id: 5,
    title: "RADYANT ISITMA",
    description: "Radyant ısıtma sistemleri, evler ve iş yerleri için gerekli olan enerjiyi verimli bir şekilde sağlar.",
    image: "/assets/radyant-isitma-sistemleri.webp", // Görsel dosya yolunu güncelle
  },
  {
    id: 6,
    title: "KOMBİ",
    description: "Kombi tesisi, evler ve iş yerleri için gerekli olan enerjiyi verimli bir şekilde sağlar.",
    image: "/assets/kombi-tesisati.webp", // Görsel dosya yolunu güncelle
  },
  {
    id: 7,
    title: "DOĞAL GAZ",
    description: "Doğal gaz tesisi, evler ve iş yerleri için gerekli olan enerjiyi verimli bir şekilde sağlar.",
    image: "/assets/dogal-gaz-tesisati.webp", // Görsel dosya yolunu güncelle
  },
  {
    id: 8,
    title: "KLİMA ",
    description: "Klima sistemleri, evler ve iş yerleri için gerekli olan enerjiyi verimli bir şekilde sağlar.",
    image: "/assets/klima-sistemleri.webp", // Görsel dosya yolunu güncelle
  }

];
export const loader = async () => {
  return {
    services: HizmetListesi,
    references: references
  }
}
export const meta: MetaFunction = () => {
  return [
    { title: "Al-De Mühendislik | Hizmetlerimiz" },
  ]
}
export default function Page() {
  const { services, references } = useLoaderData<typeof loader>();
  return (
    <div className="pt-[122px]">
      <div className="">
        <Container className="flex-wrap">
          <ServicesComponent services={services} />
        </Container>
      </div>
      <Slogans />
      <References references={references} />
    </div>
  )
}
