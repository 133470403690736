import { useEffect, useState } from "react";
import Container from "~/components/layout/Container";
import { Icon } from "~/components/ui/icon";
import { Social } from "./Social";

export default function Footer() {
  const [isBottom, setIsBottom] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setIsBottom(window.innerHeight + window.scrollY >= document.body.offsetHeight - 100);
    });
  }, []);
  return (
    <footer className="bg-[#333333] text-white">
      <Container className="py-4 flex flex-wrap">
        <div className="flex flex-wrap w-full">
          <div className="flex items-center w-full md:w-3/12">
            <a href="/" className="w-full flex mb-4 md:mb-0 justify-center md:justify-start items-center h-auto">
              <img className="grayscale-100 brightness-200 w-[80px] md:w-[120px]" src="/assets/AL-DE-muhendislik-logo.svg" alt="Al-De Mühendislik" />
            </a>
          </div>
          <div className="w-6/12 px-10 flex items-center">
            <ul className="hidden md:flex justify-between w-full [&>li]:text-[14px] [&>li]:font-medium [&>li]:text-white [&>li:before]:content-['\203A'] [&>li:before]:mr-1 [&>li:hover:before]:text-blue-400">
              <li><a href="/">Anasayfa</a></li>
              <li><a href="/kurumsal">Kurumsal</a></li>
              <li><a href="/hizmetlerimiz">Hizmetlerimiz</a></li>
              <li><a href="/blog">Blog</a></li>
              <li><a href="/iletisim">İletişim</a></li>
            </ul>
          </div>
          <div className='w-auto fixed right-8 z-50' style={{ bottom: isBottom ? '160px' : '40px' }}>
            <a href="https://wa.me/905331234567" target="_blank" rel="noreferrer" className="bg-[#25d366] block animate-bound shadow-3  shadow-black/50 rounded-full hover:text-[#25d366] hover:bg-[#fff]">
              <Icon name="mingcute:whatsapp-fill" className="w-14 h-14 m-2" />
            </a>
          </div>
        </div>
        <div className="flex flex-wrap w-full items-center font-extralight font-source-sans-pro not-italic justify-between md:-mt-8">
          <div className="flex w-full flex-wrap md:flex-nowrap items-start md:items-center">
            <div className="w-full md:w-4/12"></div>
            <div className="w-full md:w-4/12 text-center text-[14px]">
              <p>&copy; {new Date().getFullYear()} Tüm hakları saklıdır.</p>
            </div>
            <div className="w-full md:w-4/12 flex items-center gap-2 mr-4 justify-center md:justify-end">
              <Social />
            </div>
          </div>
        </div>
      </Container>
    </footer >
  );
}