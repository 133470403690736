import { type MetaFunction, useLoaderData } from "@remix-run/react";
import Container from "~/components/layout/Container";
import { references } from "../_index";
import MekanikTesisatlarComponent from "../features/MekanikTesisatlar";
import References from "../features/References";
import Slogans from "../features/Slogans";
export const MekanikTesisatListesi = [
  {
    id: 1,
    title: "Havalandırma Tesisatı",
    description: "Yaşam alanlarında temiz hava sirkülasyonu sağlamak ve iç mekan hava kalitesini artırmak için profesyonel havalandırma çözümleri.",
    image: "/assets/havalandirma-tesisati.webp", // Görsel dosya yolunu güncelle
  },
  {
    id: 2,
    title: "Yangın Tesisatı",
    description: "Yangına hızlı ve etkin müdahale için güvenilir yangın söndürme sistemleri ve tesisat çözümleri.",
    image: "/assets/yangin-tesisati.webp", // Görsel dosya yolunu güncelle
  },
  {
    id: 3,
    title: "Klima Tesisatı",
    description: "Evinizde veya iş yerinizde ideal sıcaklık ve konfor sağlayan klima montajı ve bakım hizmetleri.",
    image: "/assets/klima-tesisatlari.webp", // Görsel dosya yolunu güncelle
  },

  {
    id: 4,
    title: "Pis Su ve Temiz Su Tesisatları",
    description: "Suyun güvenli taşınması ve tahliyesi için hijyenik ve dayanıklı boru sistemleri.",
    image: "/assets/temiz-su-ve-atik-su-sistemleri.webp", // Görsel dosya yolunu güncelle
  },
  {
    id: 5,
    title: "Isıtma Tesisatları",
    description: "Mekanlarınızı enerji verimliliği ile ısıtmak için modern ve pratik çözümler.",
    image: "/assets/isitma-sistemleri.webp", // Görsel dosya yolunu güncelle
  },
  {
    id: 6,
    title: "VRF Sistemleri",
    description: "Büyük yapılar için enerji tasarruflu, esnek ve sessiz iklimlendirme sistemleri.",
    image: "/assets/kombi-tesisati.webp", // Görsel dosya yolunu güncelle
  },
  {
    id: 7,
    title: "Havuz Isıtma Tesisatı",
    description: "Yıl boyu konforlu yüzme deneyimi için güvenilir havuz ısıtma çözümleri.",
    image: "/assets/havuz-isitma-tesisati.webp", // Görsel dosya yolunu güncelle
  }
];
export const loader = async () => {
  return {
    services: MekanikTesisatListesi,
    references: references
  }
}
export const meta: MetaFunction = () => {
  return [
    { title: "Al-De Mühendislik | Hizmetlerimiz" },
  ]
}
export default function Page() {
  const { services, references } = useLoaderData<typeof loader>();
  return (
    <div className="pt-[122px]">
      <div className="">
        <Container className="flex-wrap">
          <MekanikTesisatlarComponent services={services} />
        </Container>
      </div>
      <Slogans />
      <References references={references} />
    </div>
  )
}
