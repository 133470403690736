import Container from "~/components/layout/Container";
import { tel } from "../iletisim+/features/tel";

export default function OfferBanner() {
  return (
    <section className="bg-[#f5f5f5] text-[#444444] py-12">
      <Container className="justify-center flex-wrap">
        <h2 className="text-[26px] font-bold mb-4 flex justify-center w-full flex-wrap md:flex-nowrap">HİZMETLERİMİZ İÇİN <span className="text-red-500 ml-2">BİZE ULAŞIN!</span></h2>
        <p className="flex text-center w-full justify-center flex-col text-[22px] font-light font-lato text-[#555555] mb-6 after:content-[''] after:block after:w-10 after:h-[2px] after:m-auto after:bg-[#16437c] after:mt-4">Ücretsiz keşif ve hizmetlerimiz ile alakalı bilgi alamak için bize ulaşabilirsiniz.</p>
        <a href={`tel:${tel}`} className="border border-[#16437c] text-[#16437c] hover:bg-[#16437c] hover:text-white px-4 py-2 rounded-md">
          Ücretsiz Keşif İçin Bize Ulaşın
        </a>
      </Container>
    </section>
  );
}