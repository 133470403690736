import { useEffect, useState } from "react";
import Container from "~/components/layout/Container";
import { Icon } from "~/components/ui/icon";
import { HizmetListesi } from "../hizmetlerimiz+/_index";
import { MekanikTesisatListesi } from "../mekanik-tesisatlar+/_index";
import { Social } from "./Social";

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    setIsMobile(window.innerWidth < 768);
  }, []);
  const theme = 'light'
  return (
    <header className="flex flex-wrap absolute top-0 left-0 right-0 z-20">
      <div className="flex w-full bg-[#f6f5f5] py-0 md:py-1">
        <Container className="flex items-center justify-between">
          <div className="flex items-center w-2/3 font-raleway">
            <a href="mailto:bilgi@aldemuhendislik.com" className="text-sm mr-2 text-gray-500 hidden md:block"><Icon name="bxs:envelope" /> bilgi@aldemuhendislik.com</a>
            <a href="tel:+902361234567" className="text-sm text-gray-500"><Icon name="material-symbols:phone-in-talk-watchface-indicator-sharp" /> +90 236 123 45 67</a>
          </div>
          <div className="w-1/3 flex items-center justify-end [&>a]:text-gray-500">
            <Social />
          </div>
        </Container>
      </div>
      <div className="flex w-full h-[40px] justify-between mt-8 md:mt-0 bg-red-800/90">
        <Container className="flex items-startjustify-between pt-2.5">
          <button onClick={() => setIsOpen(!isOpen)} className="md:hidden absolute right-2 top-[68px] focus:outline-none">
            <Icon name="hugeicons:list-view" className="w-7 h-7 text-white" />
          </button>
          <a href='/' className="flex md:hidden items-start mt-[-36px] bg-white p-1 shadow-[0_0_10px_0_rgba(0,0,0,0.5)] z-30"><img src="/assets/AL-DE-muhendislik-logo.svg" alt="UTB Logo" className="h-[80px] w-[80px]" /></a>
          <nav className={`w-full ${isOpen ? 'flex' : 'hidden'} md:flex flex-wrap md:flex-nowrap justify-around text-white font-raleway font-bold text-[14px] bg-[#000000ee] md:bg-transparent fixed items-start md:relative top-0 left-0 right-0 bottom-0 md:top-auto md:left-auto md:right-auto md:bottom-auto z-50 [&>a]:text-center [&>a]:pb-8 md:[&>a]:pb-0 [&>a]:border-b-2 [&>a]:border-b-gray-900 md:[&>a]:border-none [&>a]:w-full md:[&>a]:w-auto`}>
            <a href='/' className="flex md:hidden items-center justify-center md:mt-[-50px] bg-white p-1 shadow-[0_0_10px_0_rgba(0,0,0,0.5)] z-30"><img src="/assets/AL-DE-muhendislik-logo.svg" alt="UTB Logo" className="h-[120px] w-[120px]" /></a>
            <button onClick={() => setIsOpen(false)} className="md:hidden fixed right-8 top-2 focus:outline-none z-50 border-2">
              <Icon name="uil:times" className="w-7 h-7 text-black" />
            </button>
            <a href="/" className="hover:text-gray-800 text-gray-200 font-extrabold">ANASAYFA</a>
            <a href="/kurumsal" className="hover:text-gray-800 text-gray-200">KURUMSAL</a>
            <a href="/hizmetlerimiz" className="hover:text-gray-800 text-gray-200 group relative">HİZMETLERİMİZ
              {!isMobile && <ul className="!hidden md:!hidden group-hover:!block absolute left-0 top-full w-[230px] bg-white p-2 z-40">
                {HizmetListesi.map((service) => (
                  <li key={service.id}><a href={`/hizmetlerimiz/${service.id}`} className="block w-full text-gray-900 hover:bg-gray-700 hover:text-white p-2">{service.title}</a></li>
                ))}
              </ul>}
            </a>
            <a href='/' className="hidden md:flex items-start mt-[-50px] bg-white p-2 shadow-[0_0_10px_0_rgba(0,0,0,0.5)] z-30"><img src="/assets/AL-DE-muhendislik-logo.svg" alt="UTB Logo" className="h-[120px] w-[120px] p-1" /></a>
            <a href="/mekanik-tesisatlar" className="hover:text-gray-800 text-gray-200 group relative">MEKANİK TESİSAT
              {!isMobile && <ul className="!hidden md:!hidden group-hover:!block absolute bg-white p-2 z-40 w-[230px]">
                {MekanikTesisatListesi.map((service) => (
                  <li key={service.id}><a href={`/mekanik-tesisatlar/${service.id}`} className="block w-full text-gray-900 hover:bg-gray-700 hover:text-white p-2">{service.title}</a></li>
                ))}
              </ul>}
            </a>
            <a href="/blog" className="hover:text-gray-800 text-gray-200">BLOG</a>
            <a href="/iletisim" className="hover:text-gray-800 text-gray-200">İLETİŞİM</a>
          </nav>
        </Container>
      </div>
    </header>
  );
}